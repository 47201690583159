// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/opt/build/repo/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/src/pages/Dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-docs-js": () => import("/opt/build/repo/src/pages/Docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-downloads-js": () => import("/opt/build/repo/src/pages/Downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("/opt/build/repo/src/pages/Integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-style-guide-js": () => import("/opt/build/repo/src/pages/StyleGuide.js" /* webpackChunkName: "component---src-pages-style-guide-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/Support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

